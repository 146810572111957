@font-face {
    font-family: 'Encode Sans Bold';
    src:  url("../fonts/EncodeSans-Bold.ttf") format('truetype');
}

@font-face {
    font-family: 'Encode Sans';
    src:  url("../fonts/EncodeSans-Medium.ttf") format('truetype');
}

.maintenance__container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Encode Sans';
    max-width: 100vw;
    max-height: 100vh;
    background: radial-gradient(circle, rgba(59,25,127,1) 0%, rgba(15,6,32,1) 100%);

    h2{
        font-family: 'Encode Sans Bold';
        font-size: 8rem;
        line-height: 7.5rem;
        color: white;
        text-align: center;
    }

    .buttonStyle{
        padding: 8px 48px;
        border-radius: 20px;
        background-color: #aa9aff;
        border: 0px;
        color: white;
        margin-top: 1rem;
        text-decoration: none;
        font-size: 1.2rem;
        cursor: pointer;
        font-family: 'Encode Sans Bold';

        a{
            text-decoration: none;
            color: white;
        }
    }

    p{
        text-align: center;
        max-width: 100%;
        color: #aa99ff;

        span{
            color: #7733FF;
            font-weight: bolder;
            color: white;
        }
    }

    .container__main{

        background-color: white;
        width: 60%;
        padding: 3rem;
        // background: radial-gradient(circle, rgba(130,86,239,1) 0%, rgba(119,51,255,1) 100%);
        border-radius: 60px ;
        background: url('../assets//Scorp\ Purple.png') center/100% no-repeat, radial-gradient(#8256ef, #7733ff);
        width: 70vw;
        height: 75vh;
        position: relative;
        font-size: 2em;

        .info_mail{
            position: absolute;
            bottom: 10%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        img{
            width: 200px;
            position: absolute;
            top: 10%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .maintenance__left{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: center;
            align-items: center;
            margin-bottom: 4rem;
            width: 100%;
            height: 100%;
            max-width: 100vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

    }
}


@media only screen and (max-width: 700px) {
    .maintenance__container{
        max-width: 100vw;
        overflow-y: hidden;
        h2{
            font-size: 5rem;
            max-width: 90%;
            line-height: 4.5rem;
        }
    
        p{
            font-size: 1.2rem;

    
            span{

            }
        }

        .container__main{
            width: 100%;
            height: 100%;
            border-radius: 0;
            max-width: 100vw;
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: url('../assets//Scorp\ Purple.png') center/300% no-repeat, radial-gradient(#8256ef, #7733ff);

            img{
                width: 200px;
            }
        

            }
            .maintenance__left{
                width: 100%;
                max-width: 95%;
            }
            .maintenance__right{
                max-width: 95%;
            }

        }
    
    
}